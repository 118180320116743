<template>
  <Transition name="slide-up">

    <div class="row vh-100 justify-content-center align-items-center" v-if="isLoading()">
      <div class="col-12 text-center pb-5">
        <b-spinner variant="info" class="mb-5"></b-spinner>
      </div>
    </div>

    <div class="container-fluid p-0 mt-0" v-else-if="!isLoading()">

      <div class="row">
        <div class="col-lg-12">
          <div class="card mt-n4 mx-n4 mb-n5">
            <div class="bg-soft-success">
              <div class="card-body pb-4 mb-5">
                <div class="row align-items-center">
                  <div class="col-md">
                    <div class="row align-items-center">
                      <div class="col-md-auto">
                        <div class="avatar-md mb-md-0 mb-4">
                          <div class="avatar-title bg-white rounded-circle">
                            <img
                                src="https://static.wixstatic.com/media/db110e_21c6b16c1b524da3b794f2ed58cf9441~mv2.png/v1/fill/w_265,h_265,al_c,usm_0.66_1.00_0.01/Logistics%20Icon.png"
                                alt="" class="avatar-md">
                          </div>
                        </div>
                      </div>
                      <div class="col-md">
                        <h4 class="fw-semibold">
                          {{
                            order.order_number === null || order.order_number === undefined
                                ? 'We are processing your order...'
                                : 'Order number - ' + order.order_number

                          }}
                        </h4>
                        <div class="hstack gap-3 flex-wrap">
                          <div class="text-muted text-capitalize"><i class="ri-building-line align-bottom me-1"></i>
                            {{ loadData(order.position).replace('_', ' ') }}
                          </div>
                          <div class="vr"></div>
                          <div class="text-muted">Create Date : <span class="fw-medium">{{
                              loadData(order.date)
                            }}</span>
                          </div>
                          <!--                          <div class="vr"></div>-->
                          <!--                          <div class="badge rounded-pill bg-info fs-12">New</div>-->
                          <!--                          <div class="badge rounded-pill bg-danger fs-12">High</div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md text-end" v-if="order.order_number">
                    <router-link :to="{name: 'orders_wagon_update', params: {id: order.order_number}}">
                      <b-button variant="outline-dark" size="lg">
                        Edit Order
                        <i class="ri-pencil-fill align-bottom ms-1"></i>
                      </b-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xxl-9">
          <div class="card">
            <div class="card-body p-4 pt-2">
              <!-- TAB STARTS -->
              <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
                <li class="nav-item" role="presentation">
                  <a class="nav-link active" data-bs-toggle="tab" href="#actual_cost_tab"
                     role="tab" aria-selected="false" tabindex="-1"> ACTUAL COST </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" data-bs-toggle="tab" href="#preliminary_cost_tab"
                     role="tab" aria-selected="false" tabindex="-1"> PRELIMINARY COST
                  </a>
                </li>
              </ul>
              <!-- TAB ENDS -->

              <!-- TAB CONTENT STARTS -->
              <div class="tab-content text-muted">
                <div class="tab-pane active" id="actual_cost_tab" role="tabpanel">
                  <div class="table-responsive table-card">
                    <table class="table table-striped">
                      <thead>
                      <tr class="bg-light align-middle">
                        <th class="text-center">#</th>
                        <th class="text-center">Wagon</th>
                        <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Agreed Rate Per Tonne
                        </th>
                        <th class="text-center">Weight</th>
                        <th class="text-center">Additional Cost</th>
                        <th class="text-center py-0 m-0" v-for="party in order.counterparties" :key="party.id"
                            @click="updateCounterparty(party)"
                        >
                          <span class="badge bg-success">{{ party.category.name }}</span>
                          <span class="d-block">{{ party.counterparty.name }}</span>
                        </th>
                        <th class="text-center">Total</th>
                        <th class="text-center">Total Expenses</th>
                        <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Profit</th>
                      </tr>
                      <tr class="bg-light align-middle">
                        <th colspan="1"></th>
                        <th class="text-center">
                          <i v-b-tooltip.hover title="Copy Wagons"
                             @click="copyWagons()"
                             class="mdi mdi-content-copy fs-4 me-3 align-middle text-muted cursor-pointer">
                          </i>
                          <AddRow :order_id="order.id" @update="fetchData()"/>
                        </th>
                        <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
                          ${{
                            agreedRateSum.toLocaleString(undefined, {
                              minimumFractionDigits: 2, maximumFractionDigits: 2
                            })
                          }}
                        </th>
                        <th class="text-center">
                          {{ weightSum }}
                        </th>
                        <th class="text-center">
                          ${{ additionalCostSum }}
                        </th>
                        <th class="text-center py-0 m-0" v-for="party in order.counterparties" :key="party.id"
                            @click="updateCounterparty(party)"
                        >
                          ${{ calculateCounterpartyEachTotal(party) }}
                        </th>
                        <th class="text-center">
                          ${{
                            expenses.reduce((a, b) => a + sumTotal(b), 0).toFixed(2)
                          }}
                        </th>
                        <th class="text-center">
                          ${{ calculateCounterpartyTotal() }}
                        </th>
                        <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
                          ${{ sumTotalProfit }}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="align-middle"
                          v-for="(wagon, i) in expenses" :key="i"
                          @mouseover="delete_row_id=wagon.id"
                          @mouseleave="delete_row_id = null"
                      >
                        <th class="text-center">
                          <div class="btn-container me-3">
                            <Transition name="slide-up">
                              <span class="animation-item mx-2" v-if="delete_row_id !== wagon.id">{{ i + 1 }}</span>
                              <i v-else-if="delete_row_id === wagon.id"
                                 @click="delete_wagon = wagon"
                                 data-bs-toggle="modal" data-bs-target="#DeleteWagonModal"
                                 class="mdi mdi-trash-can fs-20 animation-item align-top text-danger c_icon_hoverable"></i>
                            </Transition>
                          </div>
                        </th>
                        <td class="text-center">
                          <wagon_rate_weight :code="''"
                                             @update="this.fetchData()"
                                             trackBy="Wagon" :id="wagon.id"
                                             :wagonName="wagon.wagon"/>
                        </td>
                        <td class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
                          <wagon_rate_weight @update="this.fetchData()"
                                             trackBy="Rate" :id="wagon.id"
                                             :rate="wagon.agreed_rate_per_tonn"/>
                        </td>
                        <td class="text-center">
                          <wagon_rate_weight @update="this.fetchData()"
                                             trackBy="Weight" :id="wagon.id"
                                             :actual_weight="wagon.actual_weight"/>
                        </td>
                        <td class="text-center">
                          <AdditionalCost
                              :wagon_id="wagon.id"
                              :cost="wagon.additional_cost"/>
                        </td>
                        <td class="text-center" v-for="pre_cost in wagon.actual_costs"
                            :key="pre_cost" style="max-width: 100px">
                          <ActualCostInput @update="this.fetchData()" :act="pre_cost.act" :actualCost="pre_cost"/>
                        </td>
                        <td class="text-center">
                          ${{
                            sumTotal(wagon).toLocaleString(undefined, {
                              minimumFractionDigits: 2, maximumFractionDigits: 2
                            })
                          }}
                        </td>
                        <td class="text-center">
                          ${{ sumTotalExpense(wagon) }}
                        </td>
                        <td v-if="!blacklisted_users_ids.includes(user.id)">
                          ${{ sumProfit(wagon) }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-pane" id="preliminary_cost_tab" role="tabpanel">
                  <div class="table-responsive table-card">
                    <table class="table table-striped">
                      <thead>
                      <tr class="bg-light align-middle">
                        <th class="text-center">#</th>
                        <th class="text-center">Wagon</th>
                        <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Agreed rate per ton</th>
                        <th class="text-center">Weight</th>
                        <th class="text-center">Additional Cost</th>
                        <th class="text-center py-0 m-0" v-for="party in order.counterparties" :key="party.id"
                        >
                          <span class="badge bg-success">{{ party.category.name }}</span>
                          <span class="d-block">{{ party.counterparty.name }}</span>
                        </th>
                        <th class="text-center">Total</th>
                        <th class="text-center">Total Expenses</th>
                        <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Profit</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="align-middle" v-for="(wagon, i) in expenses" :key="i">
                        <th class="text-center">{{ i + 1 }}</th>
                        <td class="text-center">
                          <input v-if="wagon.wagon !== null" class=" form-control form-control-sm w-75 max-auto"
                                 :value="wagon.wagon.name" readonly>
                          <input v-else class="form-control form-control-sm max-auto" readonly
                                 placeholder="Wagon number">
                        </td>
                        <td class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
                          <input class=" form-control form-control-sm max-auto" :value="wagon.agreed_rate_per_tonn"
                                 readonly>
                        </td>
                        <td class="text-center">
                          <input class=" form-control form-control-sm max-auto" :value="wagon.actual_weight"
                                 readonly>
                        </td>
                        <td class="text-center">
                          <input class=" form-control form-control-sm max-auto" :value="wagon.additional_cost"
                                 readonly>
                        </td>
                        <td class="text-center" v-for="pre_cost in wagon_preliminary_costs"
                            :key="pre_cost" style="max-width: 65px">
                          <input class="form-control form-control-sm w-75 mx-auto" readonly
                                 :value="pre_cost.preliminary_cost">
                        </td>
                        <td class="text-center">
                          ${{ (agreedRateSum + additionalCostSum) }}
                        </td>
                        <td class="text-center">
                          ${{ sumTotalExpense(wagon) }}
                        </td>
                        <td v-if="!blacklisted_users_ids.includes(user.id)" class="text-center">
                          ${{ sumProfit(wagon) }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- TAB CONTENT ENDS -->
            </div>
          </div>
        </div>
        <div class="col-xxl-3">
          <div class="card">
            <div class="card-header"><h5 class="card-title mb-0">Order Details</h5></div>
            <div class="card-body">
              <div class="table-responsive table-card">
                <div class="text-center py-5" v-if="isLoading()">
                  <b-spinner variant="info"></b-spinner>
                </div>
                <table class="table table-borderless align-middle mb-0" v-if="isLoading() === false">
                  <tbody>
                  <tr>
                    <td class="fw-medium">Order number</td>
                    <td> {{ loadData(order.order_number) }}</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Lot number</td>
                    <td> {{ loadData(order.lot_number) }}</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Company</td>
                    <td>
                      <router-link v-if="order.company && order.company.slug" :to="{name: 'customer_profile', params: {
                      slug: order.company.slug
                    }}">
                        {{ order.company.name }}
                      </router-link>
                      <span v-else>{{ order.company ? order.company.name || '-' : '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Product</td>
                    <td> {{ loadData(product.name + ' (' + product.hc_code + ', ' + product.etcng_code + ')') }}</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Type</td>
                    <td> {{ loadData(order.type) }}</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Departure</td>
                    <td> {{ loadData(order.departure.name + ' (' + order.departure.code) + ')' }}</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Destination</td>
                    <td> {{ loadData(order.destination.name + ' (' + order.destination.code) + ')' }}</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Shipment status</td>
                    <td class="text-capitalize"> {{ order.shipment_status }}</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Payment status</td>
                    <td class="text-capitalize"> {{ order.payment_status }}</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Manager</td>
                    <td>
                      <router-link v-if="order.manager && order.manager.slug"
                                   :to="{name: 'user_profile', params: {slug: order.manager.slug}}"
                      >
                        {{ order.manager.username }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Create Date</td>
                    <td>{{ loadData(order.date) }}</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Comment</td>
                    <td>{{ order.comment || '-' }}</td>
                  </tr>
                  </tbody>
                </table>

                <button
                    class="btn btn-soft-success fw-medium w-100"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                >See Actions
                </button>

              </div>
            </div>
          </div>

          <InvoiceList @invoiceDeleted="fetchData()"
                       :invoices_list="invoices_list"
                       :order_number="parseInt(order.order_number)"
          />

          <OrderSMGS
              :order_number="parseInt(order.order_number)"
          />

          <OrderApplications
              :application="application_file"
              :order_number="parseInt(order.order_number)"
              @application-deleted="fetchData()"
          />
        </div>
      </div>

    </div>

  </Transition>


  <b-modal v-model="showCopyWagonsModal" title="Copy Wagons" centered hide-footer size="sm">
    <div class="table-responsive">
      <table class="table table-sm table-nowrap mb-0">
        <thead>
        <tr>
          <th scope="col">Wagon</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="expense in expenses.filter(w => w.wagon)" :key="`expense_${expense}`">
          <td scope="row">{{ expense.wagon.name }}</td>
        </tr>
        <tr v-if="expenses.filter(w => w.wagon).length === 0">
          <td class="py-3">No wagons</td>
        </tr>
        </tbody>
      </table>
    </div>
  </b-modal>

  <CounterpartyActions
      v-if="!isLoading()"
      :order_number="order.order_number"
      :counterparties="order.counterparties"
      :counterparty_list="counterparty_list"
      :category_list="category_list"
      @updateCounterparties="updatedCounterparties"
  />

  <DeleteWagon @update="fetchData()"
               :wagon="delete_wagon"/>
</template>

<script>
import {ref} from "vue";
import Swal from "sweetalert2";
import axios from "axios";
import OrdersApi from "@/api/orders/orders_api";
import ActualCostInput from "@/views/pages/orders/wagon/components/ActualCostInput.vue";
import wagon_rate_weight from "@/views/pages/orders/wagon/components/wagon_rate_weight";
// import ActualCostInput from "@/views/pages/orders/components/ActualCostInput";
import CounterpartyActions from "./components/CounterpartyActions.vue";
import AdditionalCost from "@/views/pages/orders/wagon/components/AdditionalCost.vue";
import DeleteWagon from "@/views/pages/orders/wagon/components/WagonDelete.vue";

import InvoiceList from "@/views/pages/orders/wagon/components/InvoiceList.vue";
import OrderApplications from "@/views/pages/orders/wagon/components/OrderApplications.vue";

import AddRow from "@/views/pages/orders/wagon/modals/AddRow.vue";
import store from "@/state/store";
import OrderSMGS from "@/views/pages/orders/components/OrderSMGS.vue";

export default {
  name: "detail",
  data() {
    const order = []
    let product = ref(null)
    let wagon_preliminary_costs = ref([])
    let expenses = ref([])
    let container_types = ref(null)
    let updateCounterpartyInfo = ref(null)
    let counterparty_list = []
    let category_list = []
    let invoices_list = []
    let application_file = null
    let blacklisted_users_ids = []

    return {
      order,
      product,
      wagon_preliminary_costs,
      expenses,
      container_types,
      updateCounterpartyInfo,
      counterparty_list,
      category_list,
      invoices_list,
      application_file,
      blacklisted_users_ids,

      delete_row_id: null,
      delete_wagon: null,

      showCopyWagonsModal: false
    }
  },
  computed: {
    weightSum() {
      if (this.expenses) {
        return this.expenses.map(w => parseFloat(w.actual_weight)).reduce((a, b) => a + b, 0).toFixed(1)
      }
      return 0
    },
    agreedRateSum() {
      if (this.expenses) {
        return this.expenses.map(w => parseFloat(w.agreed_rate_per_tonn)).reduce((a, b) => a + b, 0)
      }
      return 0
    },
    additionalCostSum() {
      if (this.expenses) {
        return this.expenses.map(w => parseFloat(w.additional_cost)).reduce((a, b) => a + b, 0).toFixed(1)
      }
      return 0
    },
    sumTotalProfit() {
      let total = 0;

      this.expenses.forEach(wagon => {
        total += parseFloat(this.sumProfit(wagon));
      });

      return total.toFixed(2)
    },
    user() {
      return store.state.user
    }
  },
  methods: {
    calculateCounterpartyEachTotal(party) {
      try {
        let total = 0
        this.expenses.forEach(wagon => {
          wagon.actual_costs.forEach(cost => {
            if (cost.counterparty.id === party.id) {
              total += parseFloat(cost.actual_cost)
            }
          })
        })
        return parseFloat(total).toLocaleString()
      } catch {
        return 0
      }
    },
    calculateCounterpartyTotal() {
      try {
        let total = 0
        this.expenses.forEach(wagon => {
          wagon.actual_costs.forEach(cost => {
            total += parseFloat(cost.actual_cost)
          })
        })
        return parseFloat(total).toLocaleString()
      } catch {
        return 0
      }
    },
    async fetchData() {
      try {
        let response = await axios.get(`/wagon_order/list/${this.$route.params.id}/`)
        this.order = response.data[0]['order']
        this.order.id = response.data[0]['id']
        this.product = response.data[0]['product']
        this.expenses = response.data[0]['expanses'] || []
        this.wagon_preliminary_costs = response.data[0]['wagon_preliminary_costs']
        this.invoices_list = response.data[0]['order']['invoices'] || []
        this.application_file = response.data[0]['application'] || null

      } catch {
        let timerInterval
        Swal.fire({
          title: 'We could not process your request!',
          icon: 'error',
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })
      }
    },
    isLoading() {
      return this.order.order_number === null || this.order.order_number === undefined
    },
    loadData(data) {
      return this.isLoading() ? 'Loading...' : data
    },
    async getCounterpartyList() {
      if (this.counterparty_list.length > 0) return
      let orders = new OrdersApi()
      this.counterparty_list = (await orders.getCounterpartyList()).results
    },
    async getCategoryList() {
      if (this.category_list.length > 0) return;
      let orders = new OrdersApi()
      this.category_list = (await orders.getCategoryList()).results
    },
    async updateCounterparty(item) {
      const {value: formValues} = await Swal.fire({
        title: 'Update Counterparty',
        html:
            '<select class="form-select m-auto w-75 mt-3" id="categoryUpdate">' +
            `${this.category_list.map(c => {
              return item.category.id === c.id ? `<option value="${c.id}" selected>${c.name}</option>` : `<option value="${c.id}">${c.name}</option>`
            })}` +
            '</select>' +
            '<select class="form-select m-auto w-75 mt-3" id="counterpartyUpdate">' +
            `${this.counterparty_list.map(c => {
              return item.counterparty.id === c.id ? `<option value="${c.id}" selected>${c.name}</option>` : `<option value="${c.id}">${c.name}</option>`
            })}` +
            '</select>',
        focusConfirm: false,
        confirmButtonText: 'Save',
        confirmButtonColor: '#0AB39C',
        preConfirm: () => {
          return [
            document.getElementById('counterpartyUpdate').value,
            document.getElementById('categoryUpdate').value
          ]
        }
      })

      if (formValues) {
        let headers = new Headers();
        headers.append("Content-Type", `application/json`);

        let requestGetOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify({
            "category_id": formValues[1],
            "counterparty_id": formValues[0]
          }),
        };

        let response = await fetch(`${process.env.VUE_APP_ORDER_URL}/order/counterparty/update/${item.id}/`, requestGetOptions)
        if (response.status >= 200) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

          await Toast.fire({
            icon: 'success',
            title: 'Successfully updated'
          })
        }
        await this.fetchData();
      }
    },
    async createCounterparty() {
      const {value: formValues} = await Swal.fire({
        title: 'Create Counterparty',
        html:
            '<select class="form-select m-auto w-75 mt-3" id="categoryUpdate">' +
            `${this.category_list.map(c => {
              return `<option value="${c.id}">${c.name}</option>`
            })}` +
            '</select>' +
            '<select class="form-select m-auto w-75 mt-3" id="counterpartyUpdate">' +
            `${this.counterparty_list.map(c => {
              return `<option value="${c.id}">${c.name}</option>`
            })}` +
            '</select>',
        focusConfirm: false,
        confirmButtonText: 'Save',
        confirmButtonColor: '#0AB39C',
        preConfirm: () => {
          return [
            document.getElementById('counterpartyUpdate').value,
            document.getElementById('categoryUpdate').value
          ]
        }
      })

      if (formValues) {
        let headers = new Headers();
        headers.append("Content-Type", `application/json`);

        let requestGetOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify({
            "category_id": formValues[1],
            "counterparty_id": formValues[0]
          }),
        };

        let response = await fetch(`${process.env.VUE_APP_ORDER_URL}/order/counterparty/create/`, requestGetOptions)
        if (response.status >= 200) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

          Toast.fire({
            icon: 'success',
            title: 'Successfully updated'
          })
        }
        await this.fetchData();
      }
    },

    sumAgreedCosts() {
      let total = 0;

      this.container_types.forEach(ctr_type => {
        total += (parseInt(ctr_type.agreed_rate) * parseInt(ctr_type.quantity));
      });

      return total
    },

    sumProfit(wagon) {
      if (!wagon) return 0
      let agreed_rate_per_tonne = parseFloat(wagon.agreed_rate_per_tonn === null ? 1 : wagon.agreed_rate_per_tonn)
      let weight = parseFloat(wagon.actual_weight)
      let total = this.sumTotalExpense(wagon)
      let additional_cost = parseFloat(wagon.additional_cost)


      return ((agreed_rate_per_tonne * weight) - total + additional_cost).toFixed(2)
    },

    sumTotal(wagon) {
      return ((parseFloat(wagon.agreed_rate_per_tonn || 0) * parseFloat(wagon.actual_weight || 0).toFixed(1)) + parseFloat(wagon.additional_cost || 0))
    },


    sumTotalExpense(wagon) {
      return wagon.actual_costs
          .map(w => w.actual_cost)
          .reduce((a, actual_cost) => {
            return a + parseFloat(actual_cost);
          }, 0)
          .toFixed(1);
    },

    async updatedCounterparties() {
      await this.fetchData();
      await this.getCategoryList()
      await this.getCounterpartyList()
    },


    copyWagons() {
      this.showCopyWagonsModal = true
    }
  },
  async mounted() {
    await this.fetchData();
    await this.getCategoryList()
    await this.getCounterpartyList()
  },
  components: {
    OrderSMGS,
    AdditionalCost,
    ActualCostInput,
    wagon_rate_weight,
    CounterpartyActions,
    DeleteWagon,
    InvoiceList,
    OrderApplications,
    AddRow
  },
  watch: {
    "$route.params.id": {
      handler: function (val) {
        if (!val) return;
        this.fetchData();
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}


.btn-container {
  display: inline-block;
  position: relative;
  height: 1em;
}

.animation-item {
  position: absolute;
}
</style>
